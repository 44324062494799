export default {
  DRIVER_TWILIO: 'twilio',
  DRIVER_KOT: 'kot', // 簡訊王
  DRIVER_MITAKE: 'mitake', // 三竹
  DRIVER_E8D: 'every8d', // 中華電信every8d
  DRIVER_NEXMO: 'nexmo',

  STATUS_SUCCESS: 'success',
  STATUS_FAIL: 'fail'
}
