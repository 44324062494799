export default {
  'page.video': '影片',
  'module.video': '影片',

  // role
  'ROLE_VIDEO': '影片管理',

  // data
  'video.search_keyword': '輸入標題、簡述、內容搜尋',
  'video.data.type': '影片類型',
  'video.data.type.youtube': 'Youtube',
  'video.data.youtube_url': 'Youtube影片網址',

  // form
  'video.form_block.video': '影片設定',

  // others
}
