import Vue from 'vue'
import { EagleModule } from 'kernel/types/module'
import {paymentTypes} from "@/modules/provider/config/paymentConstant";
import providerApplyStatus from "@/modules/provider/config/providerApplyStatus";

class moduleIndex {
  private vm: Vue| undefined
  public name: string
  constructor() {
    this.name = 'provider'
  }

  public initVue(vm: Vue) {
    this.vm = vm
  }

  async launch(vm: Vue) : Promise<void>{
    Vue.prototype.$paymentTypes = paymentTypes

    Vue.prototype.$providerApplyStatus = providerApplyStatus

  }

  getModuleConfig() : EagleModule {
    return {
      launch: this.launch,
      menu: () => import('modules/provider/config/menu'),
      realApi: () => import('modules/provider/config/realApi'),
      routes: () => import('modules/provider/config/route'),
    }
  }
}

export default new moduleIndex()
