export default {
  'page.banner': 'Banner',
  'module.banner': 'Banner',

  // data
  'banner.data.name': 'Banner名稱',
  'banner.data.name.helper': '後台顯示用',
  'banner.data.fullscreen': '滿版',
  'banner.data.autoplay': '自動播放',
  'banner.data.interval': '自動播放間隔時間',
  'banner.data.items': 'Banner元件設定',
  'banner.empty': '尚未設定Banner',
  'banner.undefined': '未命名Banner',
  'banner_photo.data.text_position': '文字位置',
  'banner_photo.data.text_position.top': '上',
  'banner_photo.data.text_position.middle': '中',
  'banner_photo.data.text_position.bottom': '下',

  // banner type
  'banner.type.photo': '圖片',
  'banner.type.video': '影片',

  // form

  // actions
  'banner.action.edit': '編輯Banner主設定',
  'banner.action.create_item': '建立新Banner元件',

  // others
  'banner.fullscreen.recommend_size.help': '滿版樣式圖片建議尺寸: 1600px(寬) x 1600px(高)',
  'banner.recommend_size.help': '圖片建議尺寸: {width}px(寬) x {height}px(高)',
  'banner.photo.help': '建議圖片重點置於正中心，若圖片超出顯示範圍將會以中心做放大，置於中心可避免圖片重點被遮掉',
}
