export default {
  'page.store': '餐廳',
  'module.store': '餐廳',
  'module.store-member': '餐廳管理員',
  'manage-store.form_block': '管理的餐廳',

  // data
  'store.data.name': "餐廳名稱",

  // form
  'store-member.form_block': '餐廳管理員',

  // others
  'store.search_keyword': '輸入餐廳名稱搜尋',
  'store-member.search_keyword': '輸入會員名稱搜尋',
}
