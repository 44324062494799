export default {
  'page.sms-log': '簡訊紀錄',
  'module.sms': '簡訊',

  // data
  'sms.data.status': '發送狀態',
  'sms.data.status.success': '成功',
  'sms.data.status.fail': '失敗',
  'sms.data.phone_number': '電話號碼',
  'sms.data.send_at': '發送時間',
  'sms.data.message': '簡訊內容',
  'sms.data.response': 'API回應內容',
  'sms.data.error': 'API錯誤訊息',
  'sms.service': '簡訊服務',
  'sms.service.twilio': 'Twilio',
  'sms.service.nexmo': 'Nexmo',
  'sms.service.kot': '簡訊王',
  'sms.service.mitake': '三竹',
  'sms.service.every8d': '中華電信every8d',
  'sms.data.quota': '餘額',

  // form
  // 'sms.form_block.foobar': 'foobar',

  // help
  'sms.help.global': '目前使用的簡訊API為國際服務，手機號碼開頭必須去掉零後補上國際碼(例如: 台灣為+886)',

  // others
  'sms.action.send': '發送簡訊',
  'sms.send.successfully': '簡訊發送成功',
  'sms.send.failure': '簡訊發送失敗',
  'sms.send.read_more': '前往查看詳細簡訊紀錄',
  'sms.search_keyword': '輸入手機號碼、簡訊內容搜尋',
}
