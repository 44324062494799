export default {
  'page.product': '商品',
  'page.product_category': '商品分類',
  'module.product': '商品',

  // data
  'data.price': '售價',
  'product.data.stock': '庫存',
  'product.data.sku': '貨號',
  'product.data.spec': '商品規格',
  'product.data.spec.help': '例如: 顏色',
  'product.data.spec.attributes': '規格屬性',
  'product.data.spec.attributes.help': '例如: 黑、灰、白',
  'product.data.publish_start': '發布起始時間',
  'product.data.publish_end': '發布結束時間',
  'product.data.instances': '商品資料',
  'product.data.meta': '自訂商品屬性',
  'product.data.meta.key': '屬性',
  'product.data.meta.value': '值',
  'product.data.description': '商品敘述',
  'product.data.shipping_unit': '出貨單位(購買的數量單位)',
  'product.data.calculate_unit': '計價單位(可與出貨單位相同)',
  'product.data.per_shipping_count': '數量/每出貨單位',
  'product.data.price': '單價(元/計價單位)',
  'product.data.daily_alert': '每日提醒變更價格',
  

  // form
  'product.form_block.setup': '商品主資料設定',
  'product.form_block.discount_config': '量多優惠',

  // action
  'product.instance.simple_model': '檢視模式',
  'product.instance.edit_model': '編輯模式',
  'product.action.choice_product': '選擇商品',

  // others
  'product.search_keyword': '輸入供應商,商品名稱搜尋',
  'product.action.enabled_instance_config': '啟用商品規格',
  'product.action.disabled_instance_config': '關閉商品規格',
  'product.action.setup_instance_config': '設定商品規格',
  'product.action.batch_publish': '批次上架',
  'product.action.batch_close': '批次下架',
  'product.price.format': 'NT${price}',
  'product.price_range.format': 'NT${min} - NT${max}',
  'product.goto.detail_page': '前往商品設定頁',
  'product.copy.successfully': '商品複製成功',
  'product.copy.failure': '商品複製失敗',
  'product.discount_config.failure': '優惠區間錯誤',
}
