import Vue from 'vue'
import { EagleModule } from 'kernel/types/module'
import smsConstants from 'modules/sms/config/smsConstants'
class moduleIndex {
  private vm: Vue| undefined
  public name: string
  constructor() {
    this.name = 'sms'
  }

  public initVue(vm: Vue) {
    this.vm = vm
  }

  async launch(vm: Vue) : Promise<void>{
    Vue.prototype.$smsConstants = smsConstants
    Vue.prototype.$statustType = [
      smsConstants.STATUS_SUCCESS,
      smsConstants.STATUS_FAIL,
    ]
  }

  getModuleConfig() : EagleModule {
    return {
      launch: this.launch,
      menu: () => import('modules/sms/config/menu'),
      realApi: () => import('modules/sms/config/realApi'),
      routes: () => import('modules/sms/config/route'),
    }
  }
}

export default new moduleIndex()
