import Vue from 'vue'
import { EagleModule } from 'kernel/types/module'
import videoConstants from 'modules/video/config/videoConstants'
import {ModuleSelectorConfigType} from 'kernel/libs/moduleSelector'
class moduleIndex {
  private vm: Vue| undefined
  public name: string
  constructor() {
    this.name = 'video'
  }

  public initVue(vm: Vue) {
    this.vm = vm
  }

  public async launch(vm: Vue) : Promise<void>{
    Vue.prototype.$videoConstants = videoConstants
    Vue.prototype.$videoTypes = videoConstants.types

    Vue.prototype.$videoSelector = (config: ModuleSelectorConfigType) => {
      vm.$moduleSelector.active(vm, {
        ...config,
        title: vm.$t('module.video'),
        bodySlot: () => import('modules/video/selector/videoList.vue'),
      })
    }

    Vue.prototype.$previewVideo = (video: AnyObject) => {
      vm.$apopup.base({
        width: '650px',
        title: vm.$t('action.play-video'),
        // @ts-ignore
        bodySlot: () => import('components/videoPreview/videoPreview.vue'),
        video,
      })
    }
  }

  getModuleConfig() : EagleModule {
    return {
      launch: this.launch,
      menu: () => import('modules/video/config/menu'),
      realApi: () => import('modules/video/config/realApi'),
      routes: () => import('modules/video/config/route'),
    }
  }
}

export default new moduleIndex()
