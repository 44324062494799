export default {
  'page.admin.entry': '管理後台',

  'module.otp': 'OTP',
  'page.otp': 'OTP',
  'page.app-config.linerp': 'Linerp設定',
  'page.app-config.provider': '供應商設定',

  // data
  'otp.search_keyword': '輸入驗證碼、Email、手機號碼搜尋',
  'otp.data.type': '類型',
  'otp.data.feature': '用途',
  'otp.data.target': 'Email或手機號碼',
  'otp.data.code': '驗證碼',
  'otp.data.ttl': '有效時間(秒)',
  'otp.data.allow_renew': '允許重新建立的時間長度(秒)',
  'otp.data.allow_renew_at': '允許重新建立的時間',
  'otp.data.expired_at': '到期時間',
  'ga_report.overview.title': 'Google Analytics頁面瀏覽排行',
  'ga_report.overview.subtitle': '近30日內排行前10',
  'ga_report.data.pageview': '頁面瀏覽次數',
  'ga_report.data.users': '使用者造訪次數',
  'ga_report.users.title': 'Google Analytics使用者造訪分析',
  'ga_report.users.subtitle': '近30日內使用者造訪次數',

  'photo.search_keyword': '輸入圖片名稱、原始檔名、UID搜尋',
  'photo.data.type': '用途標籤',
  'photo.data.name': '圖片名稱',
  'photo.data.extension': '副檔名',
  'photo.data.source_name': '原始檔名',

  'file.search_keyword': '輸入檔名搜尋',
  'file.data.type': '用途標籤',
  'file.data.file_type': '檔案類型',
  'file.data.filename': '檔名',
  'file.data.extension': '副檔名',

  'data.main-manager': "主要管理者",
  'data.main-manager.0': "",
  'data.main-manager.1': "是",
  'data.main-manager.false': "",
  'data.main-manager.true': "是",
  'data.manager-name': '管理者姓名',

  'data.uniform_number': '統一編號',
  'data.business_name': '工商登記',
  'data.address': '地址',
  'data.ship-address': '出貨地址',
  'data.deliver-address': '送貨地址',
  'data-receive-address': '收貨地址',

  // OTP用途
  'otp.data.feature.site-admin-reset-password': '管理員重設密碼',
  'otp.data.feature.member-reset-password': '會員重設密碼',
  'otp.data.feature.member-login-via-otp': '會員重設密碼',
  'otp.data.feature.member-register': '會員註冊',

  // action
  'action.send_verify.email': '發送驗證信件',
  'ga_report.action.force_update': '強制更新',

  // others
  'otp.has_verify_code_already': '我已經有驗證碼了',
  'otp.has_verify_code_already.warning': '請先輸入Email',
}
