import Vue from 'vue'
import { EagleModule } from 'kernel/types/module'
import BannerConstants from 'modules/banner/config/bannerConstants'
class moduleIndex {
  private vm: Vue| undefined
  public name: string
  constructor() {
    this.name = 'banner'
  }

  public initVue(vm: Vue) {
    this.vm = vm
  }

  async launch() : Promise<void>{
    Vue.prototype.$bannerConstants = BannerConstants
    Vue.prototype.$bannerItemTypes = [
      BannerConstants.BANNER_ITEM_TYPE_PHOTO,
      BannerConstants.BANNER_ITEM_TYPE_VIDEO,
    ]
  }

  getModuleConfig() : EagleModule {
    return {
      launch: this.launch,
      menu: () => import('modules/banner/config/menu'),
      realApi: () => import('modules/banner/config/realApi'),
      routes: () => import('modules/banner/config/route'),
    }
  }
}

export default new moduleIndex()
