import {ActionContext} from 'vuex/types'
import {ApplicationInterface} from 'kernel/types/application'
import eagleEnv from 'config/env.json'

// @ts-ignore
import siteConfig from '@/config/site.js'

interface State {
  layoutReady: boolean,
  layoutName: string,
  application: ApplicationInterface | null,
  breadcrumbScope: string,
  breadcrumb: AnyObject[],
}

export default {
  namespaced: true,
  state: <State>{
    layoutReady: false,
    layoutName: '',
    application: null,
    breadcrumbScope: 'default',
    breadcrumb: [],
  },
  mutations: {
    setupLayoutReady: (state: State, status: boolean) : void => {
      state.layoutReady = status
    },
    setupLayoutName: (state: State, name: string) : void => {
      state.layoutName = name
    },
    initApplication: (state: State, application: ApplicationInterface) : void => {
      state.application = application
    },
    setBreadcrumb(state: State, data: AnyObject[]) {
      state.breadcrumb = data
    },
    setBreadcrumbScope(state: State, data: string) {
      state.breadcrumbScope = data
    },
  },
  actions: {
    setupLayoutReady: (context: ActionContext<string, boolean>, status: boolean) : void => {
      context.commit('setupLayoutReady', status)
    },
    setupLayoutName: (context: ActionContext<string, boolean>, name: string) : void => {
      context.commit('setupLayoutName', name)
    },
    initApplication: (context: ActionContext<string, boolean>, data: ApplicationInterface) : void => {
      context.commit('initApplication', data)
    },
    setBreadcrumb(context: ActionContext<string, boolean>, data: AnyObject[]) {
      context.commit('setBreadcrumb', data)
    },
    setBreadcrumbScope(context: ActionContext<string, boolean>, data: string) {
      context.commit('setBreadcrumbScope', data)
    },
  },
  getters: {
    application: (state: State) : ApplicationInterface|null => state.application,
    site: (state: State, getters: any) => !getters.application ? null : getters.application.site,
    siteConfig: (state: State, getters: any) => siteConfig,
    feature: (state: State, getters: any) => window.eagleLodash.get(siteConfig, ['feature']),
    siteName: (state: State, getters: any) => {
      const siteName = window.eagleLodash.get(getters.application, ['site', 'name'])
      if(!siteName) return siteConfig.name
      return siteName
    },
    breadcrumb: (state: State) : AnyObject[] => state.breadcrumb,
    breadcrumbScope: (state: State) : string => state.breadcrumbScope,
    layoutReady: (state: State) : boolean => state.layoutReady,
    recaptchaStatus: (state: State) : boolean => window.eagleLodash.get(state.application, ['recaptcha', 'status']),
    recaptchaClient: (state: State) : boolean => window.eagleLodash.get(state.application, ['recaptcha', 'client']),
    siteLogoUrl: () => require('assets/logo.png'),
    siteBlackLogoUrl: () => require('assets/logo-black.png'),
    siteIconUrl: () => require('assets/icon.png'),
    eagleEnv: () : EagleEnv => eagleEnv,
    frontendDomain: () : string => eagleEnv.frontendDomain,
    frontendWebDomain: () : string => eagleEnv.frontendWebDomain,
    fileBaseUrl: () : string => eagleEnv.fileBaseUrl,
    productCategoryMaxDepth: (state: State, getters: any) : any => window.eagleLodash.get(getters.feature, ['productCategoryMaxDepth']),
  },
}
