export default {
  'page.admin.entry': '管理後台',
  'page.site-admin': '管理員',
  'module.site_admin': '管理員',
  'module.site_admin_device': '登入裝置',

  'page.site-admin-group': '管理員群組',
  'module.site_admin_group': '管理員群組',

  'page.site-admin-log': '管理員操作紀錄',
  'module.site_admin_log': '管理員操作紀錄',

  // role
  'ROLE_MAINTAINER': '工程人員',
  'ROLE_ADMIN': '最高權限管理員',

  // data
  'site_admin.data.id': '管理員ID',
  'site_admin.data.name_email': '姓名/Email',
  'site_admin.data.name': '姓名',
  'site_admin.data.email': 'Email',
  'site_admin.data.locked': '黑名單',
  'site_admin.data.locked.true': '啟用',
  'site_admin.data.locked.false': '停用',
  'site_admin.data.roles': '權限',
  'site_admin.data.maintainer': '工程人員',
  'site_admin.data.password': '密碼',
  'site_admin.data.password_confirm': '確認密碼',

  'site_admin_device.data.fingerprint': '裝置識別碼',
  'site_admin_device.data.fingerprint.help': '每個裝置唯一的值',
  'site_admin_device.data.type': '裝置類型',
  'site_admin_device.data.type.desktop': '桌上型電腦',
  'site_admin_device.data.type.tablet': '平板',
  'site_admin_device.data.type.phone': '手機',
  'site_admin_device.data.os': '作業系統',
  'site_admin_device.data.browser': '瀏覽器',

  'site_admin_log.data.action': '操作行為',
  'site_admin_log.data.action.renew_token': '更新登入憑證(Renew Token)',
  'site_admin_log.data.action.login': '登入',
  'site_admin_log.data.action.login_fail': '登入失敗',
  'site_admin_log.data.action.logout': '登出',
  'site_admin_log.data.action.create': '新增',
  'site_admin_log.data.action.update': '更新',
  'site_admin_log.data.action.delete': '刪除',
  'site_admin_log.data.action.clone': '複製',
  'site_admin_log.data.target': '操作資料',
  'site_admin_log.data.target_ids': '操作資料對象ID',

  // form
  'site_admin.form_block.password': '設定密碼',

  // 透過驗證碼登入
  'error.login_via_otp.403': '驗證碼錯誤或失效',
  'error.login_via_otp.409': '該帳號已經被鎖住無法登入',

  // others
  'site_admin.search_keyword': '輸入姓名、Email搜尋',
  'site_admin_group.search_keyword': '輸入群組名稱、敘述搜尋',
  'site_admin_device.search_keyword': '輸入IP、裝置識別碼搜尋',
  'site_admin_log.search_keyword': '輸入管理員Email、操作資料ID搜尋',
  'site_admin.lock': '設定為黑名單',
  'site_admin.unlock': '取消黑名單',
  'site_admin_group.to_notify': '通知的管理員群組',
  'site_admin_group.action.logout': '登出裝置',
  'site_admin_group.confirm.logout': '確定要登出此裝置?',
}
