import Vue from 'vue'
import { EagleModule } from 'kernel/types/module'
import pageConstants from 'modules/page/config/pageConstants'
class moduleIndex {
  private vm: Vue| undefined
  public name: string
  constructor() {
    this.name = 'page'
  }

  public initVue(vm: Vue) {
    this.vm = vm
  }

  async launch() : Promise<void>{
    Vue.prototype.$pageConstants = pageConstants
    Vue.prototype.$pageTypes = pageConstants.types
  }

  getModuleConfig() : EagleModule {
    return {
      launch: this.launch,
      menu: () => import('modules/page/config/menu'),
      realApi: () => import('modules/page/config/realApi'),
      routes: () => import('modules/page/config/route'),
    }
  }
}

export default new moduleIndex()
