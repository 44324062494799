export default {
  // 角色
  ROLE_USER: 'user', // 使用者
  ROLE_SITE_ADMIN: 'site_admin', //管理員

  // 類型
  TYPE_DEFAULT: 'default', // 預設(聯絡我們)
  TYPE_SOFTWARE: 'software', // 軟體開發
  TYPE_ORDER_CANCEL: 'order_cancel', // 訂單取消
  TYPE_ORDER_RETURN: 'order_return', // 訂單退貨

  // 狀態
  STATUS_CREATED: 'created', // 新建立
  STATUS_IN_PROGRESS: 'in-progress', // 處理中
  STATUS_CLOSED: 'closed', // 結案
}
