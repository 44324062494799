export default {
  'page.provider': '供應商',
  'module.provider': '供應商',
  'page.provider-member': '供應商會員',
  'page.provider-config': '供應商設定頁',
  'module.provider-member': '供應商會員',
  'manage-provider.form_block': '管理的供應商',

  // data
  'provider.data.name': "供應商名稱",
  'provider.data.member_phone': "管理員聯絡電話",
  'provider.data.email': '聯絡email',
  'provider.data.bank_account_name': '帳戶名',
  'provider.data.bank_account': '銀行帳號',
  'provider.data.bank_info': '銀行資訊',
  'provider.data.bank_branch_id': '銀行分行代碼',
  'provider.data.welcome_text': 'Line@歡迎訊息',
  'provider.data.phone': '餐廳可聯絡電話',
  'provider.data.payment_type': '付款方式',
  'provider.data.payment_type.cash_on_delivery': "貨到付款",
  'provider.data.payment_type.cash_on_remit': "貨到匯款",
  'provider.data.payment_type.pre_remit': "預先匯款",
  'provider.data.payment_type.weekly': "周結",
  'provider.data.payment_type.monthly': "月結",
  'provider.data.payment_type.pay_first': "匯款後出貨",
  'provider.data.main-manager': '主要管理員',
  'provider.data.co-manager': '共同管理員',
  'provider.data.line_at_id': 'LINE@ ID',
  'provider.data.line_liff_id': 'LIFF ID',
  'provider.data.line_login_channel_id': 'LINE登入專用Channel ID',
  'provider.data.line_login_channel_secret': 'LINE登入專用Channel Secret',
  'provider.data.line_message_channel_secret': 'LINE Messaging API專用Channel Secret',
  'provider.data.line_message_channel_access_token': 'LINE Messaging API專用Channel Access Token',
  'provider.data.contact': '聯絡方式',
  'provider.data.city': '發貨縣市',
  'provider.data.district': '發貨地區',
  'provider.data.manager': "供應商管理者",
  "provider.data.created_at": "加入時間",

  // list
  'provider.search_keyword': '輸入供應商搜尋',
  'provider.search_keyword2': '輸入供應商、管理者搜尋',
  'provider-member.search_keyword': "輸入會員、email搜尋",

  // form
  'provider.form_block.payment_info': '付款資訊',
  'provider.form_block.member_info': '供應商管理者',
  'provider.form_block.line_info': "LINE@資料",

  'provider.send-verify.success': '驗整碼已寄送',
  'provider.send-verify.error': '驗整碼寄送失敗',


  // others
  'belong-to-provider': '隸屬供應商'
}
